// js-build is generated with typescript - do not edit .js files in js-build directly.
import ScrollEvent = JQuery.ScrollEvent;
import ClickEvent = JQuery.ClickEvent;
import SubmitEvent = JQuery.SubmitEvent;

declare var tinymce: any
declare type ChangeEvent = any

interface Window {
    ZIDXOptions: any;
    ZIDXMapInit: any;
    ZIDX: any;
    grecaptcha: any;
    tinyMCE: any;
    ZIDXWixLibraryLoaded: boolean;
    Wix: any;
    ZIDXWixAppInit: Function;
    ZWIXAppHeight: number;
}

if (typeof window.ZIDX == "undefined") {
    window.ZIDX = {};
}

class ZIDXOptionsContainer {
    siteDomain: string = "";
    prefix: string = "";
    freeTrial: boolean = false;
    enableRemoteSearch: boolean = false;
    zidxScrollTo: number = 0;
    enableListingPhotos: boolean = false;
    debug: boolean = false;
    thisIsTestServer: boolean = false;
    thisIsDeveloper: boolean = false;
    recaptchaSiteKey: string = "";
    versionDate: string = "";
    enableGoogleMaps: boolean = false;
    googleMapApiURL: string = "";
    wixApp: boolean = false;
    inAccount: boolean = false;
    inWixEditor: boolean = false;
    inAccountLogin: boolean = false;
    enableCookies: boolean = true;
    proxyDomain="";

    constructor() {
        let s = window.ZIDXOptions;
        this.prefix = s.prefix;
        this.freeTrial = s.freeTrial;
        this.enableRemoteSearch = s.enableRemoteSearch;
        this.zidxScrollTo = s.zidxScrollTo;
        this.enableListingPhotos = s.enableListingPhotos;
        this.siteDomain = s.siteDomain;
        this.debug = s.debug;
        this.thisIsTestServer = s.thisIsTestServer;
        this.thisIsDeveloper = s.thisIsDeveloper;
        this.recaptchaSiteKey = s.recaptchaSiteKey;
        this.versionDate = s.versionDate;
        this.enableGoogleMaps = s.enableGoogleMaps;
        this.googleMapApiURL = s.googleMapApiURL;
        this.wixApp = s.wixApp;
        this.inAccount = s.inAccount;
        this.inWixEditor = s.inWixEditor;
        this.inAccountLogin = s.inAccountLogin;
        this.enableCookies = s.enableCookies;
        this.proxyDomain=s.proxyDomain;
    }
}

class ZIDXPosition {
    x: number = 0;
    y: number = 0;
    cx: number = 0;
    cy: number = 0;
    width: number = 0;
    height: number = 0;
    innerWidth: number = 0;
    innerHeight: number = 0;
}

class ZIDXCookie {
    key: string = "";
    value: string = "";
    futureSeconds: number = -1;
    enableSubdomains: boolean = false;

    [index: string]: any;
};

interface ZIDXPage {
    render: any;

    getPageClass(): string;

    getMatchURL(): string;
}

class ZIDXPageSet {
    page: ZIDXPage | null = null;
    pageClass: string = "";
    $pageContainer: any = null;
    builtIn = false;
    alreadyLoaded = false;
}

let ZIDX = window.ZIDX;
ZIDX.options = new ZIDXOptionsContainer();
ZIDX.$ = $.noConflict();
//var ZIDX={}; defined in head instead
(function ($: JQueryStatic, window, document, undefined) {
    "use strict";
    jQuery.fn.extend({
        filterByText: function (this: any, textbox: any) {
            return this.each(function (this: any) {
                var select: any = this;
                var options: any = [];
                <any>($(select).find('option')).each(function () {
                    options.push({
                        value: $(this).val(),
                        text: $(this).text()
                    });
                });
                $(select).data('options', options);

                $(textbox).on('change keyup', function () {
                    var options = $(select).empty().data('options');
                    var search = $.trim(<string>$(textbox).val());
                    var regex = new RegExp(search, "gi");

                    $.each(options, function (i) {
                        var option = options[i];
                        if (option.text.match(regex) !== null) {
                            $(select).append(
                                $('<option>').text(option.text).val(option.value)
                            );
                        }
                    });
                });
            });
        }
    })

    let lastScreenHeight: number = 0;
    jQuery.event.special.touchstart = {
        setup: function (_, ns: any, handle: any) {
            if (typeof ns.includes != "undefined") {
                this.addEventListener("touchstart", handle, {passive: !ns.includes("noPreventDefault")});
            }
        }
    };
    jQuery.event.special.touchmove = {
        setup: function (_, ns: any, handle: any) {
            if (typeof ns.includes != "undefined") {
                this.addEventListener("touchmove", handle, {passive: !ns.includes("noPreventDefault")});
            }
        }
    };
    jQuery.event.special.wheel = {
        setup: function (_, ns: any, handle: any) {
            this.addEventListener("wheel", handle, {passive: true});
        }
    };
    jQuery.event.special.mousewheel = {
        setup: function (_, ns: any, handle: any) {
            this.addEventListener("mousewheel", handle, {passive: true});
        }
    };
    let currentDevice = -1;
    ZIDX.cookiesEnabled = function () {
        if (!ZIDX.options.enableCookies) {
            return false;
        }
        if (ZIDX.iOS() && ZIDX.inIframe()) {
            return false;
        }
        return true;
    }
    let zIsTouchscreenCache = -1;
    ZIDX.zIsTouchscreen = function () {
        if (zIsTouchscreenCache !== -1) {
            return zIsTouchscreenCache == 1;
        }
        var n = navigator.userAgent.toLowerCase();

        var patt = /(android|iphone|ipad|viewpad|tablet|bolt|xoom|touchpad|playbook|kindle|gt-p|gt-i|sch-i|sch-t|mz609|mz617|mid7015|tf101|g-v|ct1002|transformer|silk| tab)/g;
        if (n.replace(patt, "anything") !== n) {
            zIsTouchscreenCache = 1;
            return true;
        } else if (n.indexOf("MSIE 10") !== -1 && window.navigator && (typeof window.navigator.msPointerEnabled !== "undefined" && !window.navigator.msPointerEnabled)) {
            // doesn't have pointer support, touch only tablet maybe.
            zIsTouchscreenCache = 1;
            return true;
        } else {
            zIsTouchscreenCache = 0;
            return false;
        }
    }
    ZIDX.inIframe = function () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
    ZIDX.iOS = function () {
        if (currentDevice >= 0) {
            return currentDevice == 1;
        }
        let devices = [
            'iPad',
            'iPhone',
            'iPod'
        ];
        // newer
        for (let i = 0; i < devices.length; i++) {
            if (navigator.platform.indexOf(devices[i]) != -1) {
                currentDevice = 1;
                return true;
            }
        }
        // ios 13
        if (navigator.userAgent.indexOf("Mac") != -1 && "ontouchend" in document) {
            currentDevice = 1;
            return true;
        }
        currentDevice = 0;
        return false;
    };

    ZIDX.ajaxData = {};
    ZIDX.ajaxLastRequestId = false;

    ZIDX.MSIEVersion = -1;
    ZIDX.MSIEBrowser = window.navigator.userAgent.indexOf("MSIE");
    if (ZIDX.MSIEBrowser != -1) {
        ZIDX.MSIEVersion = (window.navigator.userAgent.substring(ZIDX.MSIEBrowser + 5, window.navigator.userAgent.indexOf(".", ZIDX.MSIEBrowser)));
    }
    ZIDX.getSelectValues = function (select: any) {
        var result = [];
        var options = select && select.options;
        var opt;
        var hasValue = false;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            if (options[i].value != "") {
                hasValue = true;
                break;
            }
        }
        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                if (hasValue) {
                    result.push(opt.value);
                } else {
                    result.push(opt.text);
                }
            }
        }
        return result;
    }

    ZIDX.getFormDataByFormId = function (formId: string) {
        var obj: any = {};
        ZIDX.$("input, textarea, select", ZIDX.$("#" + formId)).each(function (this: any) {
            if (typeof obj[this.name] === 'undefined') {
                if (this.type === 'checkbox' || this.type === 'radio') {
                    if (this.checked) {
                        obj[this.name] = this.value;
                    }
                } else if (this.type.substr(0, 6) === 'select') {
                    obj[this.name] = ZIDX.getSelectValues(this).join(",");
                } else {
                    obj[this.name] = this.value;
                }
            } else {
                if (this.type === 'checkbox' || this.type === 'radio') {
                    if (this.checked) {
                        obj[this.name] += "," + this.value;
                    }
                } else if (this.type.substr(0, 6) === 'select') {
                    obj[this.name] += "," + ZIDX.getSelectValues(this).join(",");
                } else {
                    obj[this.name] += "," + this.value;
                }
            }
        });
        return obj;
    }

    ZIDX.getAbsPosition = function (object: HTMLElement) {
        if (object == null || typeof object == undefined || typeof object.style == undefined || object.style.display == 'none') {
            return {x: 0, y: 0, width: 0, height: 0};
        }

        var position = new ZIDXPosition();
        position.x = 0;
        position.y = 0;
        if (object) {
            position.x = object.offsetLeft;
            position.y = object.offsetTop;

            if (object.offsetParent) {
                var parentpos = ZIDX.getAbsPosition(object.offsetParent);
                position.x += parentpos.x;
                position.y += parentpos.y;
            }
            position.cx = object.offsetWidth;
            position.cy = object.offsetHeight;
        }
        position.width = position.cx;
        position.height = position.cy;
        var $obj = ZIDX.$(object);
        position.innerWidth = $obj.innerWidth(),
            position.innerHeight = $obj.innerHeight()
        return position;
    }
    ZIDX.ajaxRequest = function (obj: any) {
        var req: any = new XMLHttpRequest();
        req.idxRequestData = {
            requestCount: 0,
            requestEndCount: 0,
            cacheData: []
        };
        if (typeof obj.formId != undefined && typeof obj.postObj != undefined) {
            alert("Only obj.formId should be used, obj.postObj is deprecated.");
        }
        var postData = "";
        if (typeof obj.formId == undefined) {
            if (typeof obj.postObj === undefined) {
                obj.postObj = {};
            }
            for (var i in obj.postObj) {
                postData += i + "=" + encodeURIComponent(obj.postObj[i]) + "&";
            }
        }
        if (typeof obj.cache === undefined) {
            obj.cache = false;
        }
        if (typeof obj.method === undefined) {
            obj.method = "get";
        }
        if (typeof obj.debug === undefined) {
            obj.debug = false;
        }
        if (typeof obj.errorCallback === undefined) {
            obj.errorCallback = function () {
                alert("There was a temporary server or network problem, please try again later.");
            };
        }
        if (typeof obj.ignoreOldRequests === undefined) {
            obj.ignoreOldRequests = false;
        }
        if (typeof obj.url === undefined || typeof obj.callback === undefined) {
            alert('ZIDX.ajaxRequest() Error: obj.url and obj.callback are required');
        }

        req.idxRequestData.requestCount++;
        req.idxRequestData.cache = obj.cache;
        req.idxRequestData.debug = obj.debug;
        req.idxRequestData.method = obj.method;
        req.idxRequestData.url = obj.url;
        req.idxRequestData.ignoreOldRequests = obj.ignoreOldRequests;
        req.idxRequestData.callback = obj.callback;
        req.idxRequestData.errorCallback = obj.errorCallback;
        if (req.idxRequestData.cache && req.idxRequestData.cacheData[obj.url] && req.idxRequestData.cacheData[obj.url].success) {
            req.idxRequestData.callback(req.idxRequestData.cacheData[obj.url].responseText);
        }
        req.showRequestError = function (m: string) {
            if (ZIDX.isDeveloper() || ZIDX.isTestServer()) {
                // alert("Unexpected response.  See console for request and response objects.  Click ok to view response.");
                if (ZIDX.options.debug) console.log("Ajax Request Object");
                if (ZIDX.options.debug) console.log(req.idxRequestData);
                if (ZIDX.options.debug) console.log("Ajax Response Object");
                if (ZIDX.options.debug) console.log(req);
                // alert("ajax request had error, see console.");
                return false;
            } else {
                // if (m != "") {
                //     alert(m);
                // }
                return true;
            }
        }
        req.onerror = function (e: any) {
            // req.showRequestError("Sorry, but that server request failed to load right now, please refresh your browser or come back later.");
        }
        req.onreadystatechange = function () {
            if (req.readyState === 4 || req.readyState === "complete" || (ZIDX.MSIEBrowser !== -1 && ZIDX.MSIEVersion <= 7 && this.readyState === "loaded")) {
                var id = req.getResponseHeader("x-ajax-id");
                if (typeof id !== undefined && new String(id).indexOf(",") !== -1) {
                    id = id.split(",")[0];
                }
                if (req.status !== 200 && req.status !== 301 && req.status !== 302) {
                    // if(id===null || id===""){
                    // 	if(ZIDX.isDeveloper() || ZIDX.isTestServer()){
                    // 		alert("You must return a x_ajax_id header with a value that matches the x_ajax_id that was sent with the request.");
                    // 	}
                    // 	if(ZIDX.ajaxLastRequestId !== false){
                    // 		id=ZIDX.ajaxLastRequestId;
                    // 		var continueExecution=req.showRequestError("");
                    // 		if(continueExecution){
                    // 			req.idxRequestData.errorCallback(req);
                    // 		}
                    // 	}else{
                    // 		req.showRequestError("Sorry, but that request response failed to load right now, please refresh your browser or come back later.");
                    // 	}
                    // }else{
                    if (req.idxRequestData.debug) {
                        req.showRequestError("");
                    } else {
                        var continueExecution = req.showRequestError("");
                        if (continueExecution) {
                        }
                    }
                    req.idxRequestData.errorCallback(req);
                    // }
                    //return;
                    // }else if(id===null || id===""){
                    // 	if(!ZIDX.isDeveloper()){
                    // 		req.showRequestError("Invalid response.  You may need to login again or refresh the page.");
                    // 	}else{
                    // 		req.showRequestError("");
                    // 	}
                    // 	return;
                }
                if (typeof req.idxRequestData !== undefined) {
                    req.idxRequestData.requestEndCount++;
                    if (!req.idxRequestData.ignoreOldRequests || req.idxRequestData.requestCount === req.idxRequestData.requestEndCount) {
                        if (req.status === 200 || req.status === 301 || req.status === 302) {
                            if (req.idxRequestData.cache) {
                                req.idxRequestData.cacheData[req.idxRequestData.url] = new Object();
                                req.idxRequestData.cacheData[req.idxRequestData.url].responseText = req.responseText.trim();
                                req.idxRequestData.cacheData[req.idxRequestData.url].success = true;
                            }
                            req.idxRequestData.callback(req.responseText.trim());
                        }
                    }

                } else {
                    alert("Request failed due to browser incompatibility.");
                }
                ZIDX.ajaxLastRequestId = false;
            }
        };
        var randomNumber = Math.random() * 1000;
        var derrUrl = "";
        if (!ZIDX.isDeveloper() && !ZIDX.isTestServer()) {
            derrUrl = "&zFPE=1";
        }
        if (req.idxRequestData.debug) {
            derrUrl = "";
        }
        ZIDX.ajaxLastRequestId = obj.id;
        var action = req.idxRequestData.url;
        // if(action.indexOf("?") === -1){
        // 	action+='?';//+derrUrl+'&ztmp='+randomNumber;
        // }else{
        // 	// action+='&'+derrUrl+'&ztmp='+randomNumber;
        // }
        // action+="&x-ajax-id="+escape(obj.id);

        var isMacSafari = false;
        if (navigator.userAgent.indexOf("Safari") != -1 && (navigator.userAgent.indexOf("Mac OS") != -1)) {
            isMacSafari = true;
            var fileFieldCache: any = {};
        }
        if (typeof window.FormData != undefined && typeof obj.formId != undefined) {
            var form = document.getElementById(obj.formId);
            if (isMacSafari) {
                var offset1 = 0;
                ZIDX.$('input[type="file"]', ZIDX.$("#" + obj.formId)).each(function (this: any) {
                    if (this.files.length == 0) {
                        fileFieldCache[offset1] = {parent: ZIDX.$(this).parent(), element: ZIDX.$(this).detach()};
                        offset1++;
                    }
                });
            }

            var formData = new FormData(<HTMLFormElement>form);
            var postObjTemp = ZIDX.getFormDataByFormId(obj.formId);
            for (var i in postObjTemp) {
                if (!formData.has(i) && postObjTemp[i] == "") {
                    formData.append(i, "");
                }
            }

            req.open(req.idxRequestData.method, action, true);
            req.setRequestHeader("x-ajax-id", obj.id);
            req.send(formData);
            setTimeout(function () {
                if (isMacSafari) {
                    for (var id in fileFieldCache) {
                        fileFieldCache[id].parent.append(fileFieldCache[id].element);
                    }
                }
            }, 500);

        } else {
            if (req.idxRequestData.method.toLowerCase() === "get") {
                req.open(req.idxRequestData.method, action, true);
                req.setRequestHeader("x-ajax-id", obj.id);
                //req.setRequestHeader("Accept-Encoding","gzip,deflate;q=0.5");
                //req.setRequestHeader("TE","gzip,deflate;q=0.5");
                req.send("");
            } else if (req.idxRequestData.method.toLowerCase() === "post") {
                //alert('not implemented - use zForm() instead');
                req.open(req.idxRequestData.method, action, true);
                req.setRequestHeader("x-ajax-id", obj.id);
                req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

                if (typeof obj.formId != undefined) {
                    var postObj: any = ZIDX.getFormDataByFormId(obj.formId);
                    var postData = "";
                    for (var i in postObj) {
                        postData += i + "=" + encodeURIComponent(postObj[i]) + "&";
                    }
                }
                req.send(postData);
            }
        }
    }
    ZIDX.isTestServer = function () {
        if (typeof ZIDX.options.thisIsTestServer !== undefined && ZIDX.options.thisIsTestServer) {
            return true;
        } else {
            return false;
        }
    }
    ZIDX.isDeveloper = function () {
        if (typeof ZIDX.options.thisIsDeveloper !== undefined && ZIDX.options.thisIsDeveloper) {
            return true;
        } else {
            return false;
        }
    }

    function getMousePosition(e: MouseEvent) {
        var sl = document.documentElement.scrollLeft;
        var st = document.documentElement.scrollTop;
        if (typeof sl === undefined) sl = document.body.scrollLeft;
        if (typeof st === undefined) st = document.body.scrollTop;
        var xMousePosMax = window.innerWidth + window.pageXOffset;
        var yMousePosMax = window.innerHeight + window.pageYOffset;
        var xMousePos = 0;
        var yMousePos = 0;
        if (e.pageX) {
            xMousePos = e.pageX;
        } else if (e.clientX) {
            xMousePos = e.clientX + (sl);
        }
        if (e.pageY) {
            yMousePos = e.pageY;
        } else if (e.clientY) {
            yMousePos = e.clientY + (st);
        }
        return {x: xMousePos, y: yMousePos, pageWidth: xMousePosMax, pageHeight: yMousePosMax};
    }

    ZIDX.mouseHitTest = function (object: HTMLElement, marginInPixels: number) {
        if (object == null || typeof object == undefined || typeof object.style == undefined || object.style.display == 'none') {
            return false;
        }


        var p = ZIDX.getAbsPosition(object);
        if (typeof marginInPixels == undefined) {
            marginInPixels = 0;
        }
        if (p.width == 0 && p.height == 0) {
            return false;
        }
        if (p.x - marginInPixels <= ZIDX.mousePosition.x) {
            if (p.x + p.width + marginInPixels >= ZIDX.mousePosition.x) {
                if (p.y - marginInPixels <= ZIDX.mousePosition.y) {
                    if (p.y + p.height + marginInPixels >= ZIDX.mousePosition.y) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    var scrollBarWidthCached = -1;
    ZIDX.getScrollBarWidth = function () {
        if (scrollBarWidthCached !== -1) {
            return scrollBarWidthCached;
        }
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild(inner);

        var b = document.documentElement || document.body;
        b.appendChild(outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        if (w1 === w2) w2 = outer.clientWidth;

        b.removeChild(outer);
        scrollBarWidthCached = (w1 - w2);
        return scrollBarWidthCached;
    };
    ZIDX.findPosition = function (obj: HTMLElement) {
        var curleft, curtop, curwidth, curheight;
        curleft = curtop = curwidth = curheight = 0;
        if (obj.offsetParent) {
            curleft = obj.offsetLeft;
            curtop = obj.offsetTop;
            curwidth = obj.offsetWidth;
            curheight = obj.offsetHeight;
            while (true) {
                obj = <HTMLElement>obj.offsetParent;
                if (typeof obj === undefined || obj === null) {
                    break;
                } else {
                    curleft += obj.offsetLeft;
                    curtop += obj.offsetTop;
                }
            }
        }
        return [curleft, curtop, curwidth, curheight];
    }
    ZIDX.jumpToId = function (id: string, offset: number) {
        var r94 = document.getElementById(id);
        if (r94 === null) return;
        var p = ZIDX.findPosition(r94);
        if (!offset || offset === null) {
            offset = 0;
        }
        console.log("jumpToId scrollTo");
        window.scrollTo(0, p[1] + offset);
    }
    ZIDX.scrollPosition = {left: 0, top: 0};

    function getClientWindowSize() {
        var myWidth = 0, myHeight = 0;
        if (typeof (window.innerWidth) === 'number') {
            //Non-IE
            myWidth = window.innerWidth;
            myHeight = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            myWidth = document.documentElement.clientWidth;
            myHeight = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            myWidth = document.body.clientWidth;
            myHeight = document.body.clientHeight;
        }
        if (ZIDX.scrollbarWidth === 0) {
            ZIDX.scrollbarWidth = ZIDX.getScrollBarWidth();
        }
        ZIDX.windowSize = {
            "width": myWidth,//-ZIDX.scrollbarWidth,
            "height": myHeight
        };
        return ZIDX.windowSize;
    }

    ZIDX.scrollbarWidth = 0;
    ZIDX.mousePosition = {x: 0, y: 0};

    function zSetScrollPosition() {
        var ScrollTop = document.body.scrollTop;
        if (ScrollTop === 0) {
            if (window.pageYOffset) {
                ScrollTop = window.pageYOffset;
            } else {
                ScrollTop = (document.body.parentElement) ? document.body.parentElement.scrollTop : 0;
            }
        }
        ZIDX.scrollPosition.top = ScrollTop;
        var ScrollLeft = document.body.scrollLeft;
        if (ScrollLeft === 0) {
            if (window.pageXOffset) {
                ScrollLeft = window.pageXOffset;
            } else {
                ScrollLeft = (document.body.parentElement) ? document.body.parentElement.scrollLeft : 0;
            }
        }
        ZIDX.scrollPosition.left = ScrollLeft;
        if (typeof window.ZWIXAppScroll != "undefined") {
            ZIDX.scrollPosition.top = Math.max(0, window.ZWIXAppScroll.scrollTop - window.ZWIXAppScroll.y);
            if (ZIDX.options.debug) console.log("wix scrollTop", ZIDX.scrollPosition.top, window.ZWIXAppScroll);
        }
        ZIDX.$(".zidxPageActive").attr("data-scroll-top", Math.round(ZIDX.scrollPosition.top));
    }

    ZIDX.$(document).on("scroll", function (e: ScrollEvent) {
        zSetScrollPosition();
        loadLazyImages();
    });

    function loadLazyImages() {
        let scrollTop = ZIDX.scrollPosition.top + ZIDX.windowSize.height;
        ZIDX.$(".zidxLazyLoadBackgroundImage").each(function (this: HTMLElement) {
            let position = this.getBoundingClientRect();
            if (scrollTop > position.top) {
                ZIDX.$(this).attr("style", ZIDX.getListingBackgroundImageCSS(this.getAttribute("data-image")!, false)).removeClass("zidxLazyLoadBackgroundImage");
            }
            // if(ZIDX.options.debug) console.log(ZIDX.scrollPosition.top, "image top", position.top);
        });
    }

    ZIDX.getListingBackgroundImageCSS = function (photoLink: string, useThumbnail: boolean): string {
        if (photoLink.indexOf("/zimageproxy/") != -1) {
            return "background-image:url(\"" + photoLink + "\");";
        } else {
            let webpLink = photoLink.replace(".jpg", ".webp");
            if (useThumbnail) {
                webpLink = webpLink.replace(".webp", "-thumbnail.webp");
            }
            return "background-image:url(\"" + photoLink + "\"); background-image: -webkit-image-set(    url(\"" + webpLink + "\") 1x); background-image: image-set(    \"" + webpLink + "\" 1x); ";
        }
    };

    ZIDX.$(document).on("mousemove", function (e: MouseEvent) {
        ZIDX.mousePosition = getMousePosition(e);
    });

    ZIDX.$(window).on("clientresize resize", function (ev: any) {
        getClientWindowSize();

        hideMenuOnResize();


        if (ZIDX.options.wixApp && window.ZIDXWixLibraryLoaded) {
            // window.Wix.getBoundingRectAndOffsets(function(e:any){
            //     if(ZIDX.options.debug) console.log("wix app bounding info", e);
            // });
            // if(ZIDX.options.debug) console.log("document clientHeight:", window.document.documentElement.clientHeight);
            // setTimeout(function(){
            setWixHeight();
            // }, 3000);
        }

        // if(ZIDX.options.wixApp && window.ZIDXWixLibraryLoaded) {
        //     window.Wix.pushState("sublink");
        //     window.Wix.setPageMetadata({
        //         title: 'Custom Title',
        //         description: 'new description'
        //     });
        // }

        //ZIDXOptions.loadOnceFunctions.push(function(){
        //	var target = Wix.Utils.getTarget();
        //	if(ZIDX.options.debug) console.log("wix target", target);
        //	Wix.getSiteInfo(function(e){
        //		if(ZIDX.options.debug) console.log("getSiteInfo in page component", e);
        //	});
        //	// Wix.Worker.getSiteInfo(function(e){
        //	// 	if(ZIDX.options.debug) console.log(e);
        //	// });
        //	// Wix.Worker.getSiteMap(function(e){
        //	// 	if(ZIDX.options.debug) console.log(e);
        //	// });
        //    Wix.addEventListener(Wix.Events.PAGE_NAVIGATION, function(page) {
        //        if(ZIDX.options.debug) console.log("Visitor navigated to page"  +  page.toPage);
        //    });
        //    Wix.addEventListener(Wix.Events.EDIT_MODE_CHANGE, function(event)  {
        //        if(ZIDX.options.debug) console.log("Edit mode changed to " + event.editMode);
        //    });

    });
    getClientWindowSize();

    ZIDX.getCookie = function (key: string) {
        if (!ZIDX.cookiesEnabled()) {
            return "";
        }
        let arrCookie = document.cookie.split(";");
        for (let i = 0; i < arrCookie.length; i++) {
            let cookie = arrCookie[i].split("=");
            if (cookie.length < 2) {
                continue;
            }
            let cookieName = cookie[0].trim();
            if (cookieName == key) {
                return unescape(cookie[1].trim());
            }
        }
        return "";
    }

    ZIDX.deleteCookie = function (key: string) {
        ZIDX.setCookie({key: key, value: "", futureSeconds: -1, enableSubdomains: false, deleting: true});
    }
    /* ZIDX.setCookie({key:"cookie",value:"value",futureSeconds:3600,enableSubdomains:false}); */
    ZIDX.setCookie = function (obj: ZIDXCookie) {
        if (!ZIDX.cookiesEnabled()) {
            return;
        }
        if (typeof obj !== "object") {
            throw("zSetCookie requires an obj like {key:'cookie'',value:'value',futureSeconds:60,enableSubdomains:false}.");
        }
        if (typeof obj.deleting == "undefined") {
            ZIDX.deleteCookie(obj.key);
        }
        var dObj: any = {futureSeconds: 0, enableSubdomains: false};
        for (var i in obj) {
            dObj[i] = obj[i];
        }
        var newC = dObj.key + "=" + escape(dObj.value);
        if (dObj.futureSeconds !== 0) {
            var currtime = new Date();
            currtime = new Date(currtime.getTime() + dObj.futureSeconds * 1000);
            newC += "; expires=" + currtime.toUTCString();
        }
        if (dObj.enableSubdomains) {
            newC += "; domain=" + window.location.hostname.replace("www.", "").replace("secure.", "");
        }
        newC += "; path=" + escape(ZIDX.options.prefix);
        if (window.location.href.indexOf("https://") != -1) {
            newC += ";secure;samesite=none";
        }
        // if (ZIDX.options.debug) console.log("set cookie:", newC);
        document.cookie = newC;
    }


    function closeOtherMenus() {
        ZIDX.$(".zidxManagerQuickMenu").removeClass("zidxActive");
        if (typeof zCurrentEditButton == "object") {
            ZIDX.$(".zidxManagerEditMenu").removeClass("zidxActive");
            ZIDX.$(".zidxManagerRowActive").removeClass("zidxManagerRowActive");
            if (typeof zCurrentEditButton != "boolean" && ZIDX.mouseHitTest(zCurrentEditButton)) {

                ZIDX.$(".zidxManagerEditMenu", ZIDX.$(zCurrentEditButton).parent()).addClass("zidxActive");
                ZIDX.$(zCurrentEditButton).parent().parent().parent().addClass("zidxManagerRowActive");
            } else {
                zCurrentEditButton = false;
            }
        }
    }

    function zManagerBindTableEvents() {
        // has to always be run because the gear links sometimes don't exist yet.
        ZIDX.$(document).on("click", ".zidxManagerQuickMenuLinks a", function (e: ClickEvent) {
            e.stopPropagation();
            return true;
        });
        ZIDX.$(document).on("click", ".zidxManagerQuickMenu", function (e: ClickEvent) {
            e.preventDefault();
            closeOtherMenus();
            setTimeout(function () {
                ZIDX.$(".zidxManagerQuickMenu").addClass("zidxActive");
            }, 30);
        });

        ZIDX.$(document).on("click", ".zidxManagerEdit", function (this: any, e: ClickEvent) {
            closeOtherMenus();
            if (ZIDX.$(".zidxManagerEditMenu", ZIDX.$(this).parent()).length == 0) {
                return true;
            }
            e.preventDefault();
            var self = this;
            setTimeout(function () {
                zCurrentEditButton = self;
            }, 30);
            ZIDX.$(".zidxManagerEditMenu", ZIDX.$(this).parent()).addClass("zidxActive");
            ZIDX.$(this).parent().parent().parent().addClass("zidxManagerRowActive");
        });
        ZIDX.$(document).on("click", function (e: ClickEvent) {
            closeOtherMenus();
        });
        ZIDX.$(document).on("click", ".zidxDeleteTableRecordRow", function (this: HTMLAnchorElement, e: ClickEvent) {
            e.preventDefault();
            deleteTableRecordRow(this, this.href);
        });
    }

    function calculateTableCells(table: HTMLTableElement) {
        let max = 0;
        for (let i = 0; i < table.rows.length; i++) {
            if (max < table.rows[i].cells.length) {
                max = table.rows[i].cells.length;
            }
        }
        return max;
    }

    function deleteTableRecordRow(obj: any, deleteLink: string) {
        let tr: any, table: any;
        let i = 0;
        while (true) {
            i++;
            if (obj.tagName.toLowerCase() == 'tr') {
                tr = obj;
            } else if (obj.tagName.toLowerCase() == 'table') {
                table = obj;
                break;
            }
            if (obj.parentNode != null) {
                obj = obj.parentNode;
            }
            if (i > 50) {
                alert('infinite loop. invalid table html structure');
                return false;
            }
        }
        var cellCount = calculateTableCells(table);

        ZIDX.$("td", tr).css("border-top", "2px solid #900");
        ZIDX.$("td", tr).css("border-bottom", "2px solid #900");
        ZIDX.$("td", tr).css("background-color", "#FFF0F0");
        var r = confirm("Are you sure you want to delete this record?");
        ZIDX.$("td", tr).css("border-top", "0px solid #CCC");
        ZIDX.$("td", tr).css("border-bottom", "1px solid #CCC");
        ZIDX.$("td", tr).css("background-color", "inherit");
        if (r) {
            let ajax = {
                id: "ajaxDeleteTableRecord",
                method: "get",
                ignoreOldRequests: true,
                callback: function (r: string) {
                    let js = JSON.parse(r);
                    if (js.success) {
                        ZIDX.$(tr).html('<td class="zidxDeletedRow" colspan="' + cellCount + '">Row Deleted</td>');
                        ZIDX.setupAjaxTableSortAgain();
                    } else {
                        alert('Failed to delete the record. Error:\n\n' + js.errorMessage);
                    }

                },
                errorCallback: function (r: string) {
                    alert('Failed to delete the record. Unknown error');
                },
                url: deleteLink
            };
            ZIDX.ajaxRequest(ajax);
        }
        return false;

    }

    ZIDX.setupAjaxTableSortAgain = function () {
        for (var tableId in ZIDX.zidxAjaxCacheTableSort) {
            var c = ZIDX.zidxAjaxCacheTableSort[tableId];
            ZIDX.setupAjaxTableSort(c.tableId, c.ajaxURL, c.ajaxVarName, c.ajaxVarNameOriginal, c.ajaxCallback);
        }
    };

    let ajaxCacheTableSort: any = {};
    let ajaxSortURLCache: any = [];
    // class AjaxTableSortCache{
    //     tableId:string="";
    //     ajaxURL:string="";
    //     ajaxVarName:string="";
    //     ajaxVarNameOriginal:string="";
    //     ajaxCallback=function(){};
    // }
    ZIDX.setupAjaxTableSort = function (tableId: string, ajaxURL: string, ajaxVarName: string, ajaxVarNameOriginal: string, ajaxCallback: Function) {

        ajaxCacheTableSort[tableId] = {
            tableId: tableId,
            ajaxURL: ajaxURL,
            ajaxVarName: ajaxVarName,
            ajaxVarNameOriginal: ajaxVarNameOriginal,
            ajaxCallback: ajaxCallback
        };

        var validated = true;
        var arrError = [];
        if (tableId == "" || ZIDX.$('#' + tableId).length == 0) {
            validated = false;
            arrError.push('queueSortCom.ajaxTableId is set to "' + tableId + '", but this table is missing.');
            return;
        }
        if (ZIDX.$('#' + tableId + ' thead').length == 0) {
            validated = false;
            arrError.push('queueSortCom.ajaxTableId is set to "' + tableId + '", but this table is missing the <thead> tag around the header rows, which is required for table row sorting to function.');
        }
        if (ZIDX.$('#' + tableId + ' tbody').length == 0) {
            validated = false;
            arrError.push('queueSortCom.ajaxTableId is set to "' + tableId + '", but this table is missing the <tbody> tag around the body rows, which is required for table row sorting to function.');
        }
        ajaxSortURLCache[tableId] = {
            url: ajaxURL
        };
        var arrSort: any[] = [];
        ZIDX.$('#' + tableId + ' tbody tr').each(function (this: HTMLTableRowElement) {
            if (this.id == '') {
                validated = false;
            } else {
                if (ZIDX.$("." + tableId + "_handle", this).length) {
                    var v = ZIDX.$("." + tableId + "_handle", this)[0].getAttribute('data-ztable-sort-primary-key-id');
                    if (v != "") {
                        arrSort.push(v);
                    }
                }
            }
        });
        var originalSortOrderList = arrSort.join("|");
        ZIDX.$("#" + tableId + " tbody").attr("data-original-sort", originalSortOrderList);
        if (validated) {
            ZIDX.$('#' + tableId + ' tbody').sortable({
                handle: '.' + tableId + '_handle',
                stop: function (e: any, e2: any) {
                    var originalSortOrderList = ZIDX.$("#" + tableId + " tbody").attr("data-original-sort");
                    var arrId = ZIDX.$("#" + tableId + " tbody").sortable("toArray");
                    var arrId2 = [];
                    for (var i = 0; i < arrId.length; i++) {
                        var v = ZIDX.$("#" + arrId[i] + " ." + tableId + "_handle").attr("data-ztable-sort-primary-key-id");
                        if (typeof v != "undefined" && v != "") {
                            arrId2.push(v);
                        }
                    }

                    var reloadOnSuccess = false;
                    // when using parent field, we can't let use sort children outside their parent.
                    var parentId = ZIDX.$(e2.item[0]).attr("data-ztable-sort-parent-id");
                    if (parentId != null && parentId != "") {
                        reloadOnSuccess = true;
                    }
                    var disableSortValidation = ZIDX.$(e2.item[0]).attr("data-ztable-sort-disable-validation");

                    var sortOrderList = arrId2.join("|");
                    //console.log("sorted list:"+sortOrderList);
                    var tempObj: any = {};
                    tempObj.id = "zidxAjaxChangeSortOrder";
                    tempObj.url = ajaxSortURLCache[tableId].url;
                    tempObj.method = "post";
                    tempObj.postObj = {};
                    if (reloadOnSuccess || disableSortValidation == "1") {
                        tempObj.postObj.zidxDisableSortValidation = 1;
                    }
                    tempObj.postObj[ajaxVarName] = sortOrderList;
                    tempObj.postObj[ajaxVarNameOriginal] = originalSortOrderList;
                    tempObj.callback = function (r: string) {
                        var d = JSON.parse(r);
                        if (!d.success) {
                            alert(d.errorMessage);
                        } else {
                            ajaxSortURLCache[tableId].cache = ZIDX.$("#" + tableId).html();

                            let arrSort: any[] = [];
                            ZIDX.$('#' + tableId + ' tbody tr').each(function (this: HTMLTableRowElement) {
                                if (this.id == '') {
                                    validated = false;
                                } else {
                                    if (ZIDX.$("." + tableId + "_handle", this).length) {
                                        var v = ZIDX.$("." + tableId + "_handle", this)[0].getAttribute('data-ztable-sort-primary-key-id');
                                        if (v != "") {
                                            arrSort.push(v);
                                        }
                                    }
                                }
                            });
                            originalSortOrderList = arrSort.join("|");
                            ZIDX.$("#" + tableId + " tbody").attr("data-original-sort", originalSortOrderList);
                            ajaxCallback(tempObj);

                            if (reloadOnSuccess) {
                                window.location.reload();
                            }
                        }
                    };
                    tempObj.errorCallback = function () {
                        alert("Failed to sort records.");
                    };
                    tempObj.cache = false;
                    tempObj.ignoreOldRequests = false;
                    ZIDX.ajaxRequest(tempObj);
                }
            });
        } else {
            arrError.push('Each <tr> row must have a unique id attribute and a data-ztable-sort-primary-key-id attribute with the value set to the primary key id for the current record.');
        }
        if (arrError.length) {
            alert(arrError.join("\n"));
        }
    }


    var zCurrentEditButton = false;
    zManagerBindTableEvents();

    ZIDX.$(".zidxManagerListTabButton").on("click", function (e: ClickEvent) {
        e.preventDefault();
        self = e.target;
        var link = ZIDX.$(self).attr("data-click-location");
        if (link && window.location.href.indexOf("searchOn=") != -1) {
            window.location.href = link;
            return;
        }
        ZIDX.$(".zidxManagerListTabButton").each(function (this: any) {
            ZIDX.$(this).removeClass("zidxActive");
            ZIDX.$(this).toggleClass("zidxMobileActive");
            if (self == this) {
                return;
            }
            var tab = ZIDX.$(this).attr("data-tab");
            if (tab != "") {
                ZIDX.$("." + tab).removeClass("zidxActive");
                ZIDX.$("." + tab).removeClass("zidxMobileActive");
            }
        });
        var tab = ZIDX.$(self).attr("data-tab");
        if (tab != "") {
            ZIDX.$(".zManagerTabContainer").show();
            ZIDX.$("." + tab).addClass("zidxActive");
            ZIDX.$("." + tab).toggleClass("zidxMobileActive");
        }
        ZIDX.$(self).addClass("zidxActive");
        ZIDX.$(self).toggleClass("zidxMobileActive");
    });

    class ExtractAjaxContentResponse {
        success: boolean = false;
        versionDate: string = "";
        errorMessage: string = "";
        forceReload: boolean = false;
        forceWindowLocationRedirect: boolean = false;
        redirectURL: string = "";
        title: string = "";
        data: string = "";
    }

    function extractAjaxContent(r: string, builtIn: boolean): ExtractAjaxContentResponse {
        var title = "";
        var body = "";
        var h = r;
        var start = '<!-- zidx-spa-version:';
        var end = ': -->';
        var startPosition = h.indexOf(start);
        var endPosition = h.indexOf(end, startPosition + start.length);
        var versionDate = "";
        var forceReload = false;
        try {
            versionDate = h.substr(startPosition + start.length, endPosition - (startPosition + start.length));
        } catch (e) {
            // couldn't parse body, might not have one
            if (ZIDX.options.debug) console.log("Failed to parse body for versionDate");
        }
        if (versionDate.length != 0 && versionDate != ZIDX.options.versionDate) {
            if (ZIDX.options.debug) console.log("Version changed, forceReload:", versionDate, "!=", ZIDX.options.versionDate);
            forceReload = true;
            body = h;
        } else {
            if (builtIn) {
                start = '<!-- zidx-spa-builtin-content-start -->';
                end = '<!-- zidx-spa-builtin-content-end -->';
                // if (ZIDX.options.debug) console.log("Looking for builtin comment");
            } else {
                start = '<!-- zidxSPABodyContent-start -->';
                end = '<!-- zidxSPABodyContent-end -->';
                // if (ZIDX.options.debug) console.log("Looking for spabodycontent comment");
            }
            startPosition = h.indexOf(start);
            endPosition = h.indexOf(end, startPosition + start.length);
            try {
                body = h.substr(startPosition + start.length, endPosition - (startPosition + start.length));
            } catch (e) {
                // couldn't parse body, might not have one
            }
            if (body.length == 0) {
                body = 'Sorry, an invalid response has been returned.<br><br>' + r;
            }
        }
        start = '<title>';
        end = '</title>';
        startPosition = h.indexOf(start);
        endPosition = h.indexOf(end, startPosition + start.length);
        try {
            title = h.substr(startPosition + start.length, endPosition - (startPosition + start.length));
        } catch (e) {
            // couldn't parse title, might not have one.
        }
        if (title == "") {
            start = 'data-spa-title="';
            end = '"';
            startPosition = h.indexOf(start);
            endPosition = h.indexOf(end, startPosition + start.length);
            if (ZIDX.options.debug) console.log("try second title");
            try {
                title = h.substr(startPosition + start.length, endPosition - (startPosition + start.length));
                if (ZIDX.options.debug) console.log("title was ", title);
            } catch (e) {
                // couldn't parse body, might not have one
            }
        }
        return {
            success: true,
            versionDate: versionDate,
            errorMessage: "",
            forceReload: forceReload,
            redirectURL: "",
            forceWindowLocationRedirect: false,
            title: title,
            data: body
        };
    }

    let disableWixSetHeight = false;
    ZIDX.getPage = function (originalURL: string, url: string, skipPushState: boolean, showAlert: boolean): ZIDXPageSet {
        if(ZIDX.options.proxyDomain!="") {
            ZIDX.options.debug=true;
            // originalURL = originalURL.replace(ZIDX.options.proxyDomain, "");
            url=url.replace(ZIDX.options.proxyDomain, "");
            // console.log("proxyDomain not empty", url, originalURL);
        }
        let pageSet = new ZIDXPageSet();
        if (typeof ZIDX.pages == "undefined") {
            if (ZIDX.options.inWixEditor) {
                ZIDX.pages = {
                    wixEditor: new ZIDXWixEditor(),
                    wixDashboard: new ZIDXWixDashboard(),
                    wixSettings: new ZIDXWixSettings(),
                };
            } else if (ZIDX.options.inAccount) {
                ZIDX.pages = {
                    accountDashboard: new ZIDXAccountDashboard(),
                    accountAutoresponderEdit: new ZIDXAccountAutoresponderEdit(),
                    accountAutoresponders: new ZIDXAccountAutoresponders(),
                    accountAdminSettings: new ZIDXAccountAdminSettings(),
                    accountListingIndexEdit: new ZIDXAccountListingIndexEdit(),
                    accountListingIndex: new ZIDXAccountListingIndex(),
                    accountListingIndexImport: new ZIDXAccountListingIndexImport(),
                    accountListingIndexFiles: new ZIDXAccountListingIndexFiles(),
                    accountListingIndexFileEdit: new ZIDXAccountListingIndexFileEdit(),
                    accountListingIndexFileFieldEdit: new ZIDXAccountListingIndexFileFieldEdit(),
                    accountListingIndexFileFields: new ZIDXAccountListingIndexFileFields(),
                    accountRemapListingIndexSelect: new ZIDXAccountRemapListingIndexSelect(),
                    accountRemapListingIndex: new ZIDXAccountRemapListingIndex(),
                    accountDataFeeds: new ZIDXAccountDataFeeds(),
                    accountDataFeedEdit: new ZIDXAccountDataFeedEdit(),
                    accountDataFeedStatus: new ZIDXAccountDataFeedStatus(),
                    accountDataAllFeedStatus: new ZIDXAccountDataAllFeedStatus(),
                    accountRetsServers: new ZIDXAccountRetsServers(),
                    accountRetsServerEdit: new ZIDXAccountRetsServerEdit(),
                    accountMlsGridServers: new ZIDXAccountMlsGridServers(),
                    accountMlsGridServerEdit: new ZIDXAccountMlsGridServerEdit(),
                    accountHelp: new ZIDXAccountHelp(),
                    accountInquiry: new ZIDXAccountInquiry(),
                    accountSetupWizard: new ZIDXAccountHelpSetupWizard(),
                    accountLeadRoutes: new ZIDXAccountLeadRoutes(),
                    accountLeadRouteEdit: new ZIDXAccountLeadRouteEdit(),
                    accountLeadAssign: new ZIDXAccountLeadAssign(),
                    accountLandingPages: new ZIDXAccountLandingPages(),
                    accountLandingPageEdit: new ZIDXAccountLandingPageEdit(),
                    accountLandingPageVersions: new ZIDXAccountLandingPageVersions(),
                    accountLeads: new ZIDXAccountLeads(),
                    accountLeadView: new ZIDXAccountLeadView(),
                    accountLeadEdit: new ZIDXAccountLeadEdit(),
                    accountListings: new ZIDXAccountListings(),
                    accountListingEdit: new ZIDXAccountListingEdit(),
                    accountPages: new ZIDXAccountPages(),
                    accountPageEdit: new ZIDXAccountPageEdit(),
                    accountBillingHistory: new ZIDXAccountBillingHistory(),
                    accountInvoice: new ZIDXAccountInvoice(),
                    accountPayment: new ZIDXAccountPayment(),
                    accountPaymentCancel: new ZIDXAccountPaymentCancel(),
                    accountPaymentPlans: new ZIDXAccountPaymentPlans(),
                    accountPaymentPlanEdit: new ZIDXAccountPaymentPlanEdit(),
                    accountSummary: new ZIDXAccountSummary(),
                    accountSavedSearches: new ZIDXAccountSavedSearches(),
                    accountSendSavedSearch: new ZIDXAccountSendListingAlerts(),
                    accountEmbeddableSearches: new ZIDXAccountEmbeddableSearches(),
                    accountEmbeddableSearchEdit: new ZIDXAccountEmbeddableSearchEdit(),
                    accountErrorLogs: new ZIDXAccountErrorLogs(),
                    accountErrorLogView: new ZIDXAccountErrorLogView(),
                    accountCloudFiles: new ZIDXAccountCloudFiles(),
                    accountDefaultSearchSettings: new ZIDXAccountDefaultSearchSettings(),
                    accountSiteAPI: new ZIDXAccountSiteAPI(),
                    accountSiteDesign: new ZIDXAccountSiteDesign(),
                    accountSiteChangePlan: new ZIDXAccountSiteChangePlan(),
                    accountSites: new ZIDXAccountSites(),
                    accountSiteEdit: new ZIDXAccountSiteEdit(),
                    accountSiteInvite: new ZIDXAccountSiteInvite(),
                    accountUserEdit: new ZIDXAccountUserEdit(),
                    accountUserCredit: new ZIDXAccountUserCredit(),
                    accountUserInvite: new ZIDXAccountUserInvite(),
                    accountUsers: new ZIDXAccountUsers(),
                    accountWidgets: new ZIDXAccountWidgets()
                };
            } else if (ZIDX.options.inAccountLogin) {
                ZIDX.pages = {
                    accountForgotPasswordCheckEmail: new ZIDXAccountForgotPasswordCheckEmail(),
                    accountForgotPassword: new ZIDXAccountForgotPassword(),
                    accountResetPassword: new ZIDXAccountResetPassword(),
                    accountRegister: new ZIDXAccountRegister(),
                    accountSiteSmtpVerify: new ZIDXAccountSiteSmtpVerify(),
                    accountSignIn: new ZIDXAccountSignIn(),
                    accountPrivacyPolicy: new ZIDXAccountPrivacyPolicy(),
                    accountTermsOfUse: new ZIDXAccountTermsOfUse()
                }
            } else {
                ZIDX.pages = {
                    siteHome: new ZIDXSiteHome(),
                    searchEmbed: new ZIDXSearchEmbedView(),
                    listingDetail: new ZIDXListingDetail(),
                    listingPhotos: new ZIDXListingPhotos(),
                    signIn: new ZIDXSignIn(),
                    inquiry: new ZIDXInquiry(),
                    privacyPolicy: new ZIDXPrivacyPolicy(),
                    termsOfUse: new ZIDXTermsOfUse(),
                    thankYou: new ZIDXThankYou(),
                    savedSearch: new ZIDXSavedSearch(),
                    landingPageDocumentation: new ZIDXLandingPageDocumentation(),
                    landingPage: new ZIDXLandingPage(),
                    savedSearches: new ZIDXSavedSearches(),
                    savedListings: new ZIDXSavedListings(),
                    savedSearchEdit: new ZIDXSavedSearchEdit(),
                    savedSearchView: new ZIDXSavedSearchView(),
                    account: new ZIDXAccount(),
                };
            }
        }
        ZIDX.$(".zidxBodyElement").removeClass("zidxDisableBodyScroll");
        let position = url.indexOf("?");
        let qs = "";
        if (position != -1) {
            qs = url.substring(position + 1);
            url = url.substring(0, position);
            if (url.length == 0) {
                url = "/";
            }
        }
        // if (ZIDX.options.debug) console.log("getPage url:", url, "qs:", qs);
        lastScreenHeight = 0;
        if (url == "/") {
            pageSet.page = ZIDX.pages.siteHome;
            pageSet.pageClass = "zidxSiteHomeContainer";
            ZIDX.$(".zidxBodyElement").addClass("zidxDisableBodyScroll");
            ZIDX.$(".zidxFooterDisclaimerContainer").hide();
            disableWixSetHeight = true;
            if (typeof window.ZWIXAppHeight != "undefined" && window.ZWIXAppHeight != 0) {
                // restore height to what it was
                // alert("Set wix height window.ZWIXAppHeight:"+window.ZWIXAppHeight);
                window.Wix.setHeight(window.ZWIXAppHeight, {overflow: false});
            }
            if (typeof window.ZWIXAppScroll != "undefined") {
                if (ZIDX.options.debug) console.log("wix on clientresize");
                window.ZIDXWixAppInit();
            }
        } else {
            disableWixSetHeight = false;
            ZIDX.$(".zidxFooterDisclaimerContainer").show();
            for (let i in ZIDX.pages) {
                let page = ZIDX.pages[i];
                let matchURL = page.getMatchURL();
                let arrParts = url.split("?");
                arrParts = arrParts[0].split("#");
                if (matchURL != "/" && arrParts[0] == matchURL) {
                    pageSet.page = page;
                    pageSet.pageClass = page.getPageClass();
                    break;
                }
            }
            if (pageSet.page == null) {
                for (let i in ZIDX.pages) {
                    let page = ZIDX.pages[i];
                    let matchURL = page.getMatchURL();
                    if (matchURL != "/" && url.substring(0, matchURL.length) == matchURL) {
                        pageSet.page = page;
                        pageSet.pageClass = page.getPageClass();
                        break;
                    }
                }
            }
        }
        if (pageSet.page != null) {
            pageSet.builtIn = true;
            pageSet.$pageContainer = ZIDX.$("." + pageSet.pageClass);
            var newContainer = false;
            if (pageSet.$pageContainer.length == 0) {
                newContainer = true;
                let page = document.createElement("div");
                page.className = "zidxPageContainer " + pageSet.pageClass + " zidxPageActive";
                page.setAttribute("data-last-url", url.split("#")[0]);
                page.setAttribute("data-last-title", "");
                let container = document.getElementById("zidxBodyContainer");
                if (container == null) {
                    throw("Template is missing element with id: zidxBodyContainer");
                }
                container.appendChild(page);
                container.insertBefore(page, container.firstElementChild);
                pageSet.$pageContainer = ZIDX.$(page);
            }
            if (pageSet.$pageContainer.length == 0) {
                throw("$pageContainer missing: ." + pageSet.pageClass);
            }
            console.log(pageSet, newContainer, pageSet.$pageContainer.attr("data-last-url"));
            let lastURL = pageSet.$pageContainer.attr("data-last-url");
            if (typeof lastURL == "undefined") {
                lastURL = "";
            }
            lastURL = lastURL.replace(ZIDX.options.prefix, "/");
            let currentURL = originalURL.replace(ZIDX.options.siteDomain, "").replace(ZIDX.options.prefix, "/");
            // console.log("compare original:", currentURL, " == lastURL without prefix:", lastURL);
            if (!newContainer && currentURL == lastURL) {
                if (!pageSet.$pageContainer.hasClass("zidxPageActive")) {
                    // if (ZIDX.options.debug) console.log("already loaded, redisplayed", originalURL);
                    ZIDX.$(".zidxPageContainer").removeClass("zidxPageActive");
                    pageSet.$pageContainer.addClass("zidxPageActive");
                    let title = pageSet.$pageContainer.attr("data-last-title");
                    document.title = title;
                    if (!skipPushState) {
                        if (typeof window.Wix != "undefined") {
                            let wixLink = url.substring(1);
                            if (ZIDX.options.debug) console.log("Wix.pushState:", wixLink, window.Wix, window.Wix.pushState);
                            window.Wix.pushState(wixLink);
                        }
                        // pushState must be run later in order to allow wix back button to work
                        setTimeout(function () {
                            if (ZIDX.options.debug) console.log("history.pushState:", originalURL);
                            window.history.pushState({}, title, originalURL);
                        }, 10);
                    }
                }
                // compare query string to alreadyLoaded url
                let arrPartOld = currentURL.split("?");
                let arrPartNew = lastURL.split("?");
                // console.log("alreadyLoadedCheck: compare old:", arrPartOld, " new: ", arrPartNew);
                if (arrPartOld.length > 1) {
                    if (arrPartNew.length > 1) {
                        if (arrPartOld[1] == arrPartNew[1]) {
                            pageSet.alreadyLoaded = true; // page was already loaded, we don't need to reload
                        }
                    }
                } else {
                    pageSet.alreadyLoaded = true; // page was already loaded, we don't need to reload
                }
            }
            // console.log("alreadyLoaded:"+pageSet.alreadyLoaded);
        } else if (showAlert) {
            alert("missing page with url: " + url);
            console.log("missing page with url: " + url);
        }
        // if (ZIDX.options.debug) console.log("matched pageSet", pageSet);
        return pageSet;
    }

    function initPage(alreadyLoaded: boolean) {
        // if (ZIDX.options.debug) console.log("ZIDX cookie versionDate:", ZIDX.options.versionDate);
        if (ZIDX.options.wixApp) {
            window.Wix.getAdsOnPage(function (e: any) {
                for (let i = 0; i < e.length; i++) {
                    let ad = e[i];
                    if (ad.top == 0) {
                        window.ZWIXBannerOffset = ad.height;
                    }
                }
            });
        }
        let recaptchaEnabled = false;
        ZIDX.$("form", ".zidxPageContainer.zidxPageActive").each(function (this: HTMLFormElement) {
            let recaptchaEnabledTemp = this.getAttribute("data-recaptcha-enabled");
            if (recaptchaEnabledTemp && recaptchaEnabledTemp == "1") {
                recaptchaEnabled = true;
                if (ZIDX.options.debug) console.log("recaptchaEnabled:", recaptchaEnabled);
            }
        });


        if (recaptchaEnabled) {
            ZIDX.loadRecaptcha();
            ZIDX.$(".grecaptcha-badge").show();
        } else {
            ZIDX.$(".grecaptcha-badge").hide();
        }
        ZIDX.$(".zidxAccordionHeader").on("click", function (this: HTMLElement, e: ClickEvent) {
            e.preventDefault();
            let id = ZIDX.$(this).attr("data-accordion-item");
            if (ZIDX.$("#" + id).hasClass("zidxAccordionOpen")) {
                ZIDX.$("#" + id).removeClass("zidxAccordionOpen");
                ZIDX.$("#" + id).addClass("zidxAccordionClosed");
            } else {
                ZIDX.$("#" + id).removeClass("zidxAccordionClosed");
                ZIDX.$("#" + id).addClass("zidxAccordionOpen");
            }
            ZIDX.$(window).trigger("resize");
            window.scrollTo(0, ZIDX.getAbsPosition(ZIDX.$("#" + id)[0]).y);
        });
        loadLazyImages();

        if (!alreadyLoaded) {
        }
        // if (ZIDX.options.debug) console.log("initPage");
        ZIDX.initTinyMCEEditors();
        ZIDX.initAccordions();
    }

    let currentPageSet: ZIDXPageSet;
    let lastLoadedURL = "";
    let disableWixPushState = false;
    let testWixPushState = false;
    let lastPageScrollTop = 0;
    ZIDX.loadPushStateURL = function (url: string, skipPushState: boolean) {
        hideMobileMenu();
        if (lastLoadedURL == "") {
            lastLoadedURL = window.location.href.replace(window.ZIDX.options.siteDomain, "").replace(ZIDX.options.prefix, "/");
        }
        url = url.split("#")[0];
        // load page with ajax
        let originalURL = url;
        // console.log("loadPushStateURL:"+url);
        url = url.replace(window.ZIDX.options.proxyDomain, "").replace(window.ZIDX.options.siteDomain, "").replace(ZIDX.options.prefix, "/");
        let currentURL = window.location.href.replace(window.ZIDX.options.siteDomain, "").replace(ZIDX.options.prefix, "/");
        // if(url==currentURL && skipPushState){
        //     return; // ignore, we're already here.
        // }
        if (url.substr(0, 7) == "http://" || url.substr(0, 8) == "https://") {
            if (ZIDX.options.debug) console.log("url didn't match, no loadPushState:", originalURL, url);
            window.location.href = url;
            return;
        }
        if (typeof window.Wix != "undefined" && !disableWixPushState) {// && ZIDX.inIframe()) {
            if (!skipPushState) {
                // alert("update push state to:"+url.substring(1));
                testWixPushState = true;
                window.Wix.pushState(url.substring(1));
                setTimeout(function () {
                    if (testWixPushState) {
                        // if wix api failed, we want to force the page to change anyway
                        disableWixPushState = true;
                        ZIDX.loadPushStateURL(originalURL, skipPushState);
                    }
                }, 100);
                return;
            }
        }
        testWixPushState = false;
        if (lastLoadedURL == url) {
            console.log("skipped pushstate, lastLoadedURL was same:" + url);
            return;
        }
        lastLoadedURL = url;
        // if(url.indexOf('/front/idxLogin/index')!=-1 && ZIDX.isLoggedIn()){
        //     window.location.href='/account/dashboard/index';
        //     return;
        // }
        // always hide map before going to next page
        if (typeof window.zidxBase != "undefined") {
            console.log("hideMap in loadPushState");
            window.zidxBase.hideMap();
        }
        let pageScrollTop = ZIDX.$(".zidxPageActive").attr("data-scroll-top");
        if (typeof pageScrollTop == "undefined") {
            pageScrollTop = 0;
        }
        lastPageScrollTop = pageScrollTop;
        let pageSet = ZIDX.getPage(originalURL, url, skipPushState, false);
        currentPageSet = pageSet;

        // if(ZIDX.options.debug) console.log(url, pageSet.page, pageSet.pageClass);
        if (pageSet.alreadyLoaded) {
            initPage(true);
            console.log("page already loaded | skipPushState:" + skipPushState);
            ZIDX.$(window).trigger("clientresize");
            // pageSet.page.render();
            if (!skipPushState) {
                setTimeout(function () {
                    console.log("loadPushStateURL scrollTo");
                    window.scrollTo(0, 0);
                }, 10);
            } else {
                if (pageSet.$pageContainer.html() == "") {
                    alert("page container class may be wrong: " + pageSet.pageClass);
                }
            }
            return;
        }

        function renderResponse(r: string) {
            var rs = new ExtractAjaxContentResponse();
            if (r.indexOf('<!DOCTYPE') != -1) {
                // full html response
                rs = extractAjaxContent(r, pageSet.builtIn);
            } else {
                try {
                    rs = JSON.parse(r);
                    if (rs.success) {
                        if (rs.versionDate.length != 0 && rs.versionDate != ZIDX.options.versionDate) {
                            if (ZIDX.options.debug) console.log("Version changed, forceReload:", rs.versionDate, "!=", ZIDX.options.versionDate);
                            rs.forceReload = true;
                        } else if (typeof rs.data != "undefined") {
                            let start = "";
                            let end = "";
                            // if (pageSet.builtIn) {
                            start = '<!-- zidx-spa-builtin-content-start -->';
                            end = '<!-- zidx-spa-builtin-content-end -->';
                            if (ZIDX.options.debug) console.log("Looking for builtin comment");
                            // } else {
                            //     start = '<!-- zidxSPABodyContent-start -->';
                            //     end = '<!-- zidxSPABodyContent-end -->';
                            //     if (ZIDX.options.debug) console.log("Looking for spabodycontent comment");
                            // }
                            let startPosition = rs.data.indexOf(start);
                            let endPosition = rs.data.indexOf(end, startPosition + start.length);
                            try {
                                rs.data = rs.data.substr(startPosition + start.length, endPosition - (startPosition + start.length));
                            } catch (e) {
                                // couldn't parse body, might not have one
                            }
                            if (rs.data.length == 0) {
                                rs.data = 'Sorry, an invalid response has been returned.<br><br>' + r;
                            }
                            // if(ZIDX.options.debug) console.log(rs.data);
                        } else {
                            rs.data = "";
                            rs.title = "";
                        }
                    }
                    // need to compare version date to local version and force reload if changed
                } catch (e) {
                    alert("Failed to parse response as JSON - see console");
                    if (ZIDX.options.debug) console.log(e, r);
                }
            }
            var title = "";
            if (rs.success) {
                document.title = rs.title;
                title = rs.title;
                // if (ZIDX.options.debug) console.log("loadPushStateURL success", rs);
                if (typeof rs.forceReload != undefined && rs.forceReload) {
                    // ZIDX.$("html").html(r.html);
                    if (ZIDX.options.debug) console.log("forceReload to " + obj.url);
                    window.location.href = obj.url;
                    return;
                } else if (typeof rs.redirectURL != 'undefined' && rs.redirectURL != "") {
                    if (ZIDX.options.debug) console.log("redirectURL");
                    if (typeof rs.forceWindowLocationRedirect != undefined && rs.forceWindowLocationRedirect) {
                        // remove the page html for security
                        if (currentPageSet != null) {
                            currentPageSet.$pageContainer.remove();
                        }
                        window.location.replace(rs.redirectURL); // we have to replace the current history state to make back button work correctly.
                    } else {
                        // alert("redirectURL must also use forceWindowLocationRedirect");
                        // return;
                        ZIDX.loadPushStateURL(rs.redirectURL, false);
                        return;
                    }
                } else {
                    if (pageSet.page != null) {
                        // if (ZIDX.options.debug) console.log("page loaded from server", url, rs);
                        // pageSet.$pageContainer.remove();
                        // let container=document.getElementById("zidxBodyContainer");
                        // if(container==null){
                        //     throw("Template is missing element with id: zidxBodyContainer");
                        // }
                        // ZIDX.$(".zidxPageContainer").removeClass("zidxPageActive");
                        // let html=$(rs.data);
                        // container.append(html.get(0));
                        // pageSet.$pageContainer=ZIDX.$("."+pageSet.pageClass);
                        pageSet.$pageContainer.attr("data-last-url", url);
                        pageSet.$pageContainer.attr("data-last-title", title);
                        ZIDX.$(".zidxPageContainer").removeClass("zidxPageActive");
                        pageSet.$pageContainer.addClass("zidxPageActive");
                        pageSet.$pageContainer.html(rs.data);
                        initPage(false);
                        pageSet.page.render();
                    } else {
                        throw("navigate with zidxSPABodyContent - we don't want to use this anymore for faster back performance - must add javascript class with getMatchURL returning: " + url);
                        // ZIDX.$(".zidxSPABodyContent").html(rs.html);
                        // if(ZIDX.options.debug) console.log("loadFunctions", ZIDXOptions.loadOnceFunctions);
                        // ZIDX.runLoadFunctions();
                    }
                }
            } else {
                ZIDX.$(".zidxSPABodyContent").html(rs.errorMessage);
            }
            if (!skipPushState) {
                if (typeof window.Wix != "undefined") {
                    let wixLink = url.substring(1);
                    if (ZIDX.options.debug) console.log("Wix.pushState2:", wixLink);
                    window.Wix.pushState(wixLink);
                } else {
                }
                // pushState must be run later in order to allow wix back button to work
                setTimeout(function () {
                    if (ZIDX.options.debug) console.log("history.pushState 2:", originalURL);
                    window.history.pushState({}, title, originalURL);
                }, 10);
            } else {
                if (ZIDX.options.debug) console.log("skipPushState");
            }

            if (ZIDX.options.wixApp) {
                setWixHeight();
            }
            scrollToTop();
        }

        let ajaxURL=originalURL;
        if(window.location.href.startsWith(ZIDX.options.proxyDomain)){
            // console.log("proxy mode before ajaxSPA get", originalURL, "prefix", ZIDX.options.prefix);
            if(ajaxURL.startsWith(ZIDX.options.proxyDomain)){
                ajaxURL=ajaxURL.replace(ZIDX.options.proxyDomain, "");
            }
            if(ajaxURL.startsWith(ZIDX.options.prefix)){
                ajaxURL=ajaxURL.replace(ZIDX.options.prefix, "/");
            }
        }
        let obj = {
            id: "ajaxSPA",
            method: "get",
            postObj: {},
            ignoreOldRequests: false,
            callback: renderResponse,
            errorCallback: function (e: XMLHttpRequest) {
                if (e.status == 0) {
                    // works when offline
                    ZIDX.$(".zidxSPABodyContent").html('<div class="zidxContainer zidx-pv-40"><h1>Sorry, the request could not be completed.</h1><p>Your Internet connection may have failed or the server is having a temporary problem. Please try again later.</p></div>.');
                } else {
                    renderResponse(e.responseText);
                }
            },
            url: ZIDX.options.siteDomain+ajaxURL
        };
        console.log("try ajax request", obj.url);
        ZIDX.ajaxRequest(obj);
    }

    function scrollToTop() {
        window.scrollTo(0, 0);

        if (ZIDX.options.wixApp) {
            // window.Wix.getBoundingRectAndOffsets(function(e:any){
            //     if(ZIDX.options.debug) console.log("wix app bounding info", e);
            // });
            if (typeof window.ZWIXAppScroll != "undefined") {
                window.Wix.scrollTo(0, window.ZWIXAppScroll.y - window.ZWIXBannerOffset);
            } else {
                window.Wix.scrollTo(0, 0);
            }
        }
    }

    function setWixHeight() {
        let bodyContainer = document.getElementById("zidxBodyContainer")!;
        let bodyPosition = bodyContainer.getBoundingClientRect();
        console.log("try setWixHeight ", bodyPosition.height, " disableWixSetHeight:", disableWixSetHeight);
        // if (ZIDX.options.debug) console.log("try setWixHeight disableWixSetHeight:", disableWixSetHeight);
        if (disableWixSetHeight) {
            return;
        }
        if (lastScreenHeight == bodyPosition.height) {
            // if (ZIDX.options.debug) console.log("wix document height unchanged");
            return;
        }
        lastScreenHeight = bodyPosition.height;
        window.Wix.setHeight(lastScreenHeight, {overflow: false});
        // alert("Set wix height:"+lastScreenHeight);
        if (ZIDX.options.debug) console.log("wix document height change", lastScreenHeight);
    }

    function hideMobileMenu() {
        if (ZIDX.$(".zidxMobileMenu").length > 0) {
            ZIDX.$(".zidxMobileMenu").removeClass("zidxOpen");
            ZIDX.$(".zidxMobileHeaderOverlay").removeClass("zidxOpen");
            ZIDX.$(".zidxMobileMenuIcon").removeClass("zidxOpen");
            ZIDX.$(".zManagerEditMenu").removeClass("zidxActive");
            ZIDX.$("body").removeClass("zidxMobileMenuDisableScroll");
        }
    }

    // this is a hack to force javascript to execute when back button is used.
    window.onbeforeunload = function () {
    };

    // end hack

    function hideMenuOnResize() {
        if (ZIDX.windowSize.width >= 992 && ZIDX.$(".zidxMobileMenu").hasClass("zidxOpen")) {
            hideMobileMenu();
        }
    }


    ZIDX.$(window).on("popstate", function (e: any) {

        // if (ZIDX.options.debug) console.log("popstate fired", e);
        let state = (<PopStateEvent>e.originalEvent).state;
        var arrLink = window.location.href.split("#");
        var link = arrLink[0];
        if (state != null && typeof state.disableSPABack != "undefined" && state.disableSPABack) {
            link = link.replace("ajaxLoad=", "ztv=");
            // if (ZIDX.options.debug) console.log("popstate disableSPABack: link:"+link);
            // return;
        }
        // let linkTemp = link.replace(ZIDX.options.siteDomain, "");
        // if (linkTemp.substring(0, 5) == "http:" || linkTemp.substring(0, 6) == "https:") {
        //
        // } else {
        //     linkTemp=linkTemp.replace(ZIDX.options.prefix, "/");
        // }
        // if(linkTemp==""){
        //     linkTemp="/";
        // }
        // console.log("popstate loading url:", linkTemp);
        console.log("popstate loading url:", link);
        ZIDX.loadPushStateURL(link, true);
        // we go back once more in wix app because we stored pushState for both the wix site and the iframe
        // this is wrong on wix app
        // if (typeof window.Wix != "undefined") {
        //     let wixLink=link.replace(window.ZIDX.options.siteDomain, "").replace(ZIDX.options.prefix, "/").substring(1);
        //     window.Wix.pushState(wixLink);
        //     if(ZIDX.iOS()){
        //         alert("popstate on ios");
        //     }else if(ZIDX.inIframe()){
        //         window.history.back();
        //     }
        // }
    });

    function getDomain(link: string) {
        if (link.substr(0, 8) == "https://" || link.substr(0, 7) == "http://") {
            var p = link.indexOf("/", 9);
            link = link.substr(0, p - 1);
        } else {
            link = window.location.href;
            var p = link.indexOf("/", 9);
            link = link.substr(0, p - 1);
        }
        var arrLink = link.split("#");
        return arrLink[0];
    }

    ZIDX.$(document).on("click", "a", function (this: any, e: ClickEvent) {
        if (this.target == "_blank") {
            console.log("nav: return true on new window");
            e.preventDefault();
            window.open(this.href);
            return false;
        }
        let arrLink = this.href.split("#");
        let link = arrLink[0].replace(ZIDX.options.siteDomain, "");
        // console.log("nav link click", link);
        let arrDisableSPALinks = ["zidxDisableSPALink"];
        for (let i = 0; i < arrDisableSPALinks.length; i++) {
            if (ZIDX.$(this).hasClass(arrDisableSPALinks[i])) {
                // console.log("nav: disable spa");
                console.log("nav: return true on disable spa link");
                return true;
            }
        }
        if (this.href.substr(this.href.length - 1, 1) == "#") {
            e.preventDefault();
            console.log("nav: return false on hash");
            return false;
        }
        if (typeof ZIDX.options.prefix != undefined && ZIDX.options.prefix != "") {
            // check if url contains prefix
            let currentPageDomain = getDomain(window.location.href);
            if (link.indexOf(currentPageDomain) != -1 && (link.substr(0, 8) == "https://" || link.substr(0, 7) == "http://")) {
                link = link.replace("https://", "").replace("http://", "");
                let p = link.indexOf("/");
                link = link.substr(p);
            }
            // need the root relative url
            if (link.substr(0, ZIDX.options.prefix.length) != ZIDX.options.prefix) {
                if (!ZIDX.$(this).hasClass("zidxEnableSPA")) {
                    console.log("nav: enable spa return true");
                    return true;
                }
            }
        }
        if (ZIDX.$(".zidxSPABodyContent").length > 0) {
            console.log("nav: loadpushstate");
            // alert("loadpushstate")
            ZIDX.loadPushStateURL(link, false);
            e.preventDefault();
            return false;
        }
        console.log("nav: regular click");
        window.location.href = link;
    });

    ZIDX.redirectFormResponse = function (r: any, redirectOnSaveURL: string, ) {
        document.title = r.title;
        if (typeof r.redirectURL != 'undefined') {
            if (r.redirectURL.startsWith('http')) {
                window.location.href = r.redirectURL;
                return;
            }

            if (redirectOnSaveURL != "") {
                r.redirectURL = redirectOnSaveURL;
            }
            if (typeof r.forceWindowLocationRedirect != undefined && r.forceWindowLocationRedirect) {
                // remove the page html for security
                if (currentPageSet != null) {
                    currentPageSet.$pageContainer.remove();
                }
                window.location.replace(window.zidxBase.urlAppend(r.redirectURL.replaceAll("zidxScrollTo=", "disableZIDXScrollTo="), "zidxScrollTo=" + lastPageScrollTop));
                // window.location.replace(r.redirectURL);
            } else {
                ZIDX.loadPushStateURL(r.redirectURL, false);
            }
        } else {
            if (ZIDX.options.debug) console.log(r);
            alert("Invalid response - see console");
        }
    }

    function submitFormData(formId: string) {
        let formElement: any = document.getElementById(formId);
        // let postObj=ZIDX.getFormDataByFormId(formId);

        let redirectOnSaveURL = "";
        let redirectField = ZIDX.$("#redirectOnSaveURL" + formId);
        if (redirectField.length > 0) {
            redirectOnSaveURL = redirectField.val();
        }
        let obj = {
            id: "ajaxIDXFormSubmission",
            method: "post",
            formId: formId,
            // postObj:postObj,
            ignoreOldRequests: false,
            callback: function (r1: string) {
                let r = JSON.parse(r1);
                if (r.success) {
                    ZIDX.redirectFormResponse(r, redirectOnSaveURL)
                } else {
                    let captchaImage=ZIDX.$("#zidxCaptchaImage"+formId);
                    if(captchaImage.length>0){
                        captchaImage.attr("src","/captcha/?t="+new Date().getTime());
                        let captchaInput=ZIDX.$("#zidxCaptchaInput"+formId);
                        captchaInput.val("");
                    }
                    scrollToTop();
                    ZIDX.$("#zidxformStatusMessage" + formId).html('<div class="zidxErrorStatusMessage"><p style="margin-top:0px;font-weight:bold;">Please correct these items and try again.</p><p>' + r.errorMessage.replace(/\n/g, "<br>") + '</p></div>');
                }
                // if(skipPushState==false){
                // 	window.history.pushState({}, title, obj.url);
                // }
                // console.log("scrollToTop3");
                // scrollToTop();
                // hideMobileMenu();
            },
            errorCallback: function (e: any) {
                scrollToTop();
                ZIDX.$("#zidxformStatusMessage" + formId).html('<div class="zidxErrorStatusMessage"><h1>Sorry, the request could not be completed.</h1><p>Please try again later</p></div>');
            },
            url: formElement.action
        };
        ZIDX.ajaxRequest(obj);
    }

    ZIDX.$(document).on("submit", ".zidxForm", function (this: any, e: SubmitEvent) {
        if (ZIDX.$(this).attr("method") == "get") return;
        e.preventDefault();
        let formId = ZIDX.$(this).attr("id");
        let self = this;
        let recaptchaEnabled = ZIDX.$(this).attr("data-recaptcha-enabled");
        ZIDX.$("#zidxformStatusMessage" + formId).html('');

        let fileUploadingField = <HTMLInputElement>document.getElementById("fileUploadingField" + formId);
        if (fileUploadingField) {
            let uploading = parseInt(fileUploadingField.value);
            if (uploading > 0) {
                setTimeout(function () {
                    $(self).trigger("submit");
                }, 500);
                return false;
            }
        }

        if (typeof recaptchaEnabled != "undefined" && recaptchaEnabled == "1") {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(ZIDX.options.recaptchaSiteKey, {action: 'submit'}).then(function (token: string) {
                    ZIDX.$("#recaptchaToken" + formId).val(token);
                    // console.log(formId, token);
                    submitFormData(formId);
                });
            });
        } else {
            submitFormData(formId);
        }
        return false;
    });


    ZIDX.isLoggedIn = function () {
        if (ZIDX.getCookie("ZIDXLOGGEDIN") == "") {
            return false;
        }
        return true;
    }
    setInterval(function () {
        if (typeof ZIDX.options.inAccount != undefined && ZIDX.options.inAccount) {
            if (!ZIDX.isLoggedIn() && window.location.href.indexOf('/login') == -1) {
                window.location.href = "/login?zlogout=1&loginRedirectURL=" + escape(window.location.href);
            }
        }
    }, 1000);

    var runFunctionsIntervalID = 0;
    var firstRunLoadFunctions = true;
    ZIDX.getVisibleWindowRect = function (): DOMRect {
        let domRect = new DOMRect();
        // domRect.x=;
        // domRect.y=;
        // domRect.width=;
        // domRect.height=;
        return domRect;
    }
    let wixAppPosition: any;
    let wixAppScrollPosition: any;
    ZIDX.getPrefixURL = function (link: string): string {
        link = link.replace(ZIDX.options.siteDomain, "");
        if (link.substring(0, 5) == "http:" || link.substring(0, 6) == "https:") {
            return link;
        } else {
            return ZIDX.options.prefix + link.substring(1);
        }
    }
    let lastURL = "";
    ZIDX.runLoadFunctions = function () {
        if (ZIDX.options.wixApp && !window.ZIDXWixLibraryLoaded) {
            setTimeout(function () {
                ZIDX.runLoadFunctions();
            }, 30);
            return;
        }
        let originalURL = window.location.href;
        let url = originalURL.replace(ZIDX.options.siteDomain, "").replace(ZIDX.options.prefix, "/");
        url = url.split("#")[0];
        if (lastURL == url) {
            return; // don't repeat more than one per page
        }
        lastURL = url;
        if (firstRunLoadFunctions) {
            firstRunLoadFunctions = false;
            let arrURL = url.split("?");

            // YOU CAN NEVER REMOVE THE wix query string.  On iOS is breaks the wix iframe api.
            // if (arrURL.length == 2 && arrURL[1].indexOf("instance=") != -1) {
            //     url = arrURL[0];
            //     // this removes the extra parameters from the url for wix app
            //     history.replaceState({}, document.title, ZIDX.options.prefix + arrURL[0].substring(1));
            //
            //
            // }
            // if (ZIDX.options.debug) console.log("runLoadFunctions url: " + url);
            let isSuccessPage = document.getElementById("zidxResponseError") == null;
            let pageSet = ZIDX.getPage(originalURL, url, true, isSuccessPage);
            if (pageSet.page != null) {
                pageSet.$pageContainer.attr("data-last-url", url.split("#")[0]);
                window.scrollTo(0, ZIDX.options.zidxScrollTo);
                if (isSuccessPage && pageSet.$pageContainer.html() == "") {
                    alert("page container class may be wrong: " + pageSet.pageClass);
                }
                currentPageSet = pageSet;
                initPage(false);
                if (pageSet.page != null) {
                    pageSet.page.render();
                }
            }
            if (ZIDX.options.wixApp) {
                if (arrURL[0] != "/") {
                    setWixHeight();
                }
            }
            //     if(ZIDX.options.wixApp) {
            //         let scrollerContainer=ZIDX.$(".zidxListingScrollerContainer")[0];
            //         // setTimeout(function(){
            //             scrollerContainer.style.position="relative";
            //             scrollerContainer.style.height="auto";
            //         // },2000);
            //         let gridContainer=ZIDX.$(".zidxSearchResultsGridContainer")[0];
            //         let searchContainer=ZIDX.$(".zidxSearchContainer")[0];
            //         // gridContainer.style.height="auto";
            //         // gridContainer.style.overflow="visible";
            //         // gridContainer.style.overflowY="visible";
            //         let searchContainerPosition=searchContainer.getBoundingClientRect();
            //         let gridPosition=gridContainer.getBoundingClientRect();
            //         let mapContainer=ZIDX.$(".zidxSearchMapContainer")[0];
            //         let firstResize=true;
            //         if(disableWixSetHeight) {
            //             // window.Wix.setHeight(2000, {overflow: false});
            //             // if(ZIDX.options.debug) console.log("scrollTo before");
            //             // window.Wix.scrollTo(0, 1);
            //             // if(ZIDX.options.debug) console.log("scrollTo after");
            //             // window.Wix.scrollTo(0, 0);
            //             // if(ZIDX.options.debug) console.log("scrollTo after2");
            //             // let firstHeight=0;
            //             // let finalHeight=0;
            //             // window.Wix.addEventListener(window.Wix.Events.SCROLL, function (event: any) {
            //             //     if(ZIDX.options.debug) console.log(event);
            //             //     if(firstHeight==0) {
            //             //         firstHeight = event.documentHeight - event.y;
            //             //         window.Wix.setHeight(event.documentHeight - event.y, {overflow: false});
            //             //     //     window.Wix.scrollTo(0, 10000);
            //             //     // }else if(finalHeight==0){
            //             //     //     finalHeight=firstHeight-event.scrollTop;
            //             //     //     window.Wix.setHeight(firstHeight-event.scrollTop, {overflow: false});
            //             //     //     window.Wix.scrollTo(0, event.scrollTop);
            //             //     }
            //             //     window.Wix.getBoundingRectAndOffsets(function(e:any){
            //             //         if(ZIDX.options.debug) console.log("wix app bounding info", e);
            //             //         wixAppPosition=e;
            //             //         // {
            //             //         //     "offsets": {
            //             //         //         "x": 0,
            //             //         //         "y": 150
            //             //         //     },
            //             //         //     "rect": {
            //             //         //         "left": 0,
            //             //         //         "right": 1893,
            //             //         //         "top": 150,
            //             //         //         "bottom": 650,
            //             //         //         "height": 500,
            //             //         //         "width": 1893
            //             //         //     },
            //             //         //     "scale": 1
            //             //         // }
            //             //         wixAppPosition=e;
            //             //
            //             //
            //             //         // this can force us to get the scroll event to fire
            //             //         // window.Wix.scrollTo(0,1);
            //             //         // window.Wix.scrollTo(0,0);
            //             //     });
            //             // });
            //         }
            //
            //         // because wix scroll is delayed, we can move the elements before the wix event fires to make it not flicker!
            //         // this didn't work any better
            //         // window.addEventListener('wheel', function(event)
            //         // {
            //         //     if(ZIDX.options.debug) console.log("scroll inside iframe", event.deltaY);
            //         //     // this is a relative movement
            //         //     let mapContainerPosition=mapContainer.getBoundingClientRect();
            //         //     if(ZIDX.options.debug) console.log("mapContainerPosition be", mapContainerPosition);
            //         //     ZIDX.$(mapContainer).stop(true, true).css({opacity:0, top:(mapContainerPosition.top+event.deltaY)+"px"}).animate({
            //         //         opacity:1
            //         //     }, "fast");
            //         //     // mapContainer.style.top=(mapContainerPosition.top+event.deltaY)+"px";
            //         //     mapContainerPosition=mapContainer.getBoundingClientRect();
            //         //     if(ZIDX.options.debug) console.log("mapContainerPosition af", mapContainerPosition);
            //         // });
            //         window.addEventListener('wheel', function(event) {
            //             return;
            //             if(wixAppScrollPosition.scrollTop>wixAppScrollPosition.y) {
            //                 ZIDX.$(searchContainer).stop(true, true).css({opacity: 0});
            //                 ZIDX.$(mapContainer).stop(true, true).css({opacity:0});
            //             }
            //         });
            //         window.Wix.addEventListener(window.Wix.Events.SCROLL, function (event:any) {
            //             // {"scrollTop": 4, "scrollLeft": 0, "documentHeight": 724, "documentWidth": 1227, "x": 124, "y": 131, "height": 682, "width": 978, "left": 124.5, "bottom": 809, "right": 1102.5, "top": 127}
            //             wixAppScrollPosition=event;
            //
            //             // window.Wix.setHeight(event.documentHeight, {overflow: false});
            //             // window.Wix.scrollTo(0, event.y-50);
            //             return;
            //             let addHeight=0;
            //             if(wixAppScrollPosition.scrollTop>wixAppScrollPosition.y) {
            //                 searchContainer.style.position="fixed";
            //                 searchContainer.style.background="#FFF";
            //                 searchContainer.style.zIndex="1000";
            //                 // searchContainer.style.top=((wixAppScrollPosition.scrollTop-wixAppScrollPosition.y)+gridPosition.top)+"px";
            //                 let top=((wixAppScrollPosition.scrollTop-wixAppScrollPosition.y)+gridPosition.top);
            //                 ZIDX.$(searchContainer).stop(true, true).css({opacity:0, top:top+"px"}).animate({
            //                     opacity:1
            //                 }, "slow");
            //                 let searchContainerPosition2=searchContainer.getBoundingClientRect();
            //                 addHeight+=searchContainerPosition2.height;
            //             }else{
            //                 searchContainer.style.background="";
            //                 searchContainer.style.opacity="1";
            //                 searchContainer.style.position="relative";
            //                 searchContainer.style.top=(0)+"px";
            //             }
            //
            //             let scrollTopWithinIframe=Math.max(0, wixAppScrollPosition.scrollTop-wixAppScrollPosition.y)+searchContainerPosition.bottom+addHeight;
            //             if(ZIDX.options.debug) console.log("wix window scrolled", event);
            //             if(firstResize) {
            //                 // setTimeout(function(){
            //                 mapContainer.style.display = "block";
            //                 // },10);
            //                 mapContainer.style.backgroundColor = "#900";
            //                 // mapContainer.style.top=(scrollTopWithinIframe)+"px";
            //                 ZIDX.$(mapContainer).stop(true, true).css({opacity:0, top:scrollTopWithinIframe+"px"}).animate({
            //                     opacity:1
            //                 }, "slow");
            //                 mapContainer.style.left = (500) + "px";
            //                 mapContainer.style.position = "fixed";
            //                 mapContainer.style.zIndex = "1";
            //                 mapContainer.style.width = (100) + "px";
            //                 let newHeight = wixAppScrollPosition.documentHeight - wixAppScrollPosition.y - gridPosition.top;
            //                 if(ZIDX.options.debug) console.log("newHeight", newHeight);
            //                 mapContainer.style.height = (newHeight) + "px";
            //             }
            //         });
            //         if(ZIDX.options.debug) console.log("before getBounding");
            //         // window.Wix.getBoundingRectAndOffsets(function(e:any){
            //         //     if(ZIDX.options.debug) console.log("wix app bounding info", e);
            //         //     wixAppPosition=e;
            //         //     // {
            //         //     //     "offsets": {
            //         //     //         "x": 0,
            //         //     //         "y": 150
            //         //     //     },
            //         //     //     "rect": {
            //         //     //         "left": 0,
            //         //     //         "right": 1893,
            //         //     //         "top": 150,
            //         //     //         "bottom": 650,
            //         //     //         "height": 500,
            //         //     //         "width": 1893
            //         //     //     },
            //         //     //     "scale": 1
            //         //     // }
            //         //     wixAppPosition=e;
            //         //
            //         //
            //         //     // this can force us to get the scroll event to fire
            //         //     window.Wix.scrollTo(0,1);
            //         //     window.Wix.scrollTo(0,0);
            //         // });
            //         if(ZIDX.options.debug) console.log("after getBounding");
            //         // window.Wix.addEventListener(window.Wix.Events.STYLE_PARAMS_CHANGE, function (event:any) {
            //         //     if(ZIDX.options.debug) console.log("wix app settings styles changed", event);
            //         // });
            //         // window.Wix.addEventListener(window.Wix.Events.SETTINGS_CHANGE, function (event:any) {
            //         //     if(ZIDX.options.debug) console.log("wix app settings styles changed", event);
            //         // });
            //         setWixHeight();
            //     }
            //     if(ZIDX.options.debug) console.log("first load");
        }
        if (window.ZIDXOptions.loadOnceFunctions.length > 0) {
            var a = window.ZIDXOptions.loadOnceFunctions;
            window.ZIDXOptions.loadOnceFunctions = [];
            // if(ZIDX.options.debug) console.log('runLoad count:'+a.length);
            for (var i = 0; i < a.length; i++) {
                a[i]();
            }
        }
        if (window.ZIDXOptions.loadFunctions.length > 0) {
            var a = window.ZIDXOptions.loadFunctions;
            // if(ZIDX.options.debug) console.log('runLoad count:'+a.length);
            for (var i = 0; i < a.length; i++) {
                a[i]();
            }
        }

        // if(ZIDX.options.wixApp) {
        //     setWixHeight();
        // }
        if (runFunctionsIntervalID == 0) {
            runFunctionsIntervalID = setInterval(function () {
                ZIDX.runLoadFunctions();
            }, 30);
        }
    }

    function setupFileChangeEvent(formId: string, obj: HTMLInputElement, isImage: boolean) {
        let self = obj;
        ZIDX.$("#" + self.id + "_file", ".zidxPageActive").on("change", function (this: HTMLInputElement) {
            let progressElement = (<HTMLProgressElement>document.getElementById(self.id + "_progress")!);
            $(progressElement).show();
            let previewElement = (<HTMLDivElement>document.getElementById(self.id + "_preview")!);
            let fieldElement = (<HTMLInputElement>document.getElementById(self.id + "")!);
            let urlElement = (<HTMLInputElement>document.getElementById(self.id + "_url")!);
            let userTable = (<HTMLInputElement>document.getElementById(self.id + "_usertable")!).value;
            let imageSizes = "";
            if (document.getElementById(self.id + "_imagesizes") != null) {
                imageSizes = (<HTMLInputElement>document.getElementById(self.id + "_imagesizes")!).value;
            }
            // console.log("change file", self.id, userTableElement);
            let isUserTable = false;
            if (userTable == "1") {
                isUserTable = true;
            }
            let uploadProgress = new ZIDXFileUploadProgress(<HTMLFormElement>document.getElementById(formId), previewElement, fieldElement, this, isImage, isUserTable, imageSizes, urlElement, progressElement);
            uploadProgress.doUpload();
        });

    }

    ZIDX.loadCloudFileFields = function () {
        let form = ZIDX.$(".zidxForm", ".zidxPageActive");
        if (form.length > 0) {
            let formId = form[0].id;
            ZIDX.$(".zidxCloudFileField", ".zidxPageActive").each(function (this: HTMLInputElement) {
                setupFileChangeEvent(formId, this, false);
            });
            ZIDX.$(".zidxCloudImageField", ".zidxPageActive").each(function (this: HTMLInputElement) {
                setupFileChangeEvent(formId, this, true);
            });
        }
    };
    window.ZIDXOptions.loadFunctions.push(function () {
        ZIDX.loadCloudFileFields();
    });

    let recaptchaLoaded = false;
    ZIDX.loadRecaptcha = function () {
        if (recaptchaLoaded) return; // make sure we only load the javascript library once.
        if (ZIDX.options.debug) console.log("loadRecaptcha");
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=" + ZIDX.options.recaptchaSiteKey;
        ZIDX.$("head")[0].appendChild(script);
        recaptchaLoaded = true;
    }

    let tinyMCELoaded = false;
    ZIDX.loadTinyMCE = function (callback: Function) {
        if (tinyMCELoaded) {
            callback();
            return; // make sure we only load the javascript library once.
        }
        if (ZIDX.options.debug) console.log("loadTinyMCE");

        return ZIDX.$.getScript("/js/tiny_mce/tinymce.min.js", (t: any, u: any) => {
            tinyMCELoaded = true
            callback()
        });
    }

    // function to load tinymce lib if there are any if there is at least one tinymce form field
    ZIDX.initTinyMCEEditors = function () {
        let elements: JQuery = ZIDX.$("textarea.zidxTinyMCEEditor");
        if (!elements.length) return;
        console.log("trying to load tinymce editors");
        ZIDX.loadTinyMCE(() => {
            tinymce.remove("textarea.zidxTinyMCEEditor");
            // prevent reusing editors
            tinymce.EditorManager.editors = [];
            // we need that otherwise not found errors
            tinymce.baseURL = '/js/tiny_mce'
            tinymce.suffix = '.min'
            // no need to generate unique id for tiny mce editors since we target element directly
            elements.each((index, element) => {
                console.log("load tinymce editor:" + index);
                let options = {
                    target: element,
                    branding: false,
                    menubar: false,
                    plugins: [
                        ' advlist autolink lists link image charmap print preview anchor textcolor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code'
                    ],
                    content: '',
                    toolbar: 'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | link bullist numlist outdent indent | removeformat',
                    content_css: [],
                    setup: function (ed: any) {
                        ed.on('blur', function (e: any) {
                            if (typeof window.tinyMCE != "undefined") {
                                window.tinyMCE.triggerSave();
                            }
                        });
                    },
                    width: element.getAttribute("width"),
                    height: element.getAttribute("height"),
                    fix_table_elements: 0,
                    document_base_url: '/',
                    convert_urls: 0,
                    browser_spellcheck: true,
                    gecko_spellcheck: true,
                    paste_remove_spans: 1,
                    remove_script_host: 0,
                    relative_urls: 0,
                    forced_root_block: 'p',
                }
                tinymce.init(options)
                    .then((editors: any) => {
                        editors.forEach((e: any) => {
                            e.setContent(element.textContent)
                        })
                    })
            })
        })
    }

    ZIDX.initAccordions = function () {
        ZIDX.$(".zidxFormAccordion").accordion({
            header: '.zidxFormAccordionHeader',
            collapsible: true,
            active: false,
            heightStyle: 'content',
        });
    }

    ZIDX.getVersionURL = function (link: string, useForeverCache: boolean) {
        if (link.substr(0, 2) == "//") {
            return link;
        }
        if (link.substr(0, 1) != "/") {
            throw("link, \"" + link + "\", must start with a slash for versioning to work.");
        }
        if (link.substr(0, 4) == "/zv.") {
            throw("link, \"" + link + "\", is already versioned.");
        }
        var prefix = "";
        // if(useForeverCache && !ZIDX.isTestServer()){
        //     if(link.substr(0, 3) == "/z/"){
        //         return prefix+"/zv"+ZIDX.options.versionDate+"/_zforever"+link;
        //     }else if(link.substr(0, 9) == "/zupload/"){
        //         return prefix+"/zv"+ZIDX.options.versionDate+"/_zuploadforever"+link;
        //     }else{
        //         return prefix+"/zv"+ZIDX.options.versionDate+"/_forever"+link;
        //     }
        // }else{
        return prefix + "/zv" + ZIDX.options.versionDate + link;
        // }
    }


    // TODO: we could implement service worker someday, requires https, and we must know all JS/CSS we want it to cache etc
    // ZIDX.installServiceWorker=function(){
    //     if ('serviceWorker' in navigator) {
    //         navigator.serviceWorker.getRegistrations().then(registrations => {
    //             if(registrations.length == 0){
    //                 var link="/serviceWorker";
    //                 var appRoute=false;
    //                 var scope="/";
    //                 if(appRoute){
    //                     // scope=ZIDX.options.prefix;
    //                     // link=ZIDX.options.prefix+"service-worker/";
    //                 }else{
    //                     navigator.serviceWorker.register(link, {scope:scope}).then(function(registration) {
    //                         if(ZIDX.options.debug) console.log('Service worker registered successfully', registration);
    //                         // registration.unregister();
    //                     }).catch(function(err) {
    //                         if(ZIDX.options.debug) console.log('Service worker registration failed: ', err);
    //                     });
    //                 }
    //             }
    //         });
    //     }
    // }
    // if(!ZIDX.isTestServer()){
    //     setTimeout(function(){
    //         ZIDX.installServiceWorker();
    //     },10);
    // }
})(ZIDX.$, window, document, "undefined");
